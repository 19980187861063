<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('unihall_quotas_price_title')" :isColumns="true" :isNewButton="true"
                    @new-button-click="openForm('add')" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('unihall_quotas_price_title')" :isColumns="true" :isNewButton="true"
                    @new-button-click="openForm('add')" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="false" @filter="filter" @filterClear="filterClear">

            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="formModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ formModalTitle.toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <DataForm :formId="formId" :formType="formType" v-model="formData" :disabled="formDisabled"
                        @close="formClear(true)" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'

// Services
import UnihallQuotaPriceService from '@/services/UnihallQuotaPriceService';

// Other
import qs from 'qs';

export default {
    name: "UnihallQuotaPrice",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        SemestersSelectbox,
        DataForm: () => import("./DataForm")
    },
    metaInfo() {
        return {
            title: this.$t('unihall_quotas_price_title').toUpper()
        };
    },
    data() {
        return {
            formId: null,
            formType: "",
            formData: {},
            formDisabled: false,
            transferUpdateStatus: "",
            reject: {
                undergraduate_transfer_id: null,
                explanation: null,
            },
            delete: {
                undergraduate_transfer_id: null,
            },
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                hidden: true,
                                callback: (row) => {
                                    this.openForm('show', row);
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line',
                                hidden: true,
                                callback: (row) => {
                                    this.openForm('update', row);
                                }
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                callback: (row) => {
                                    this.openForm('delete', row);
                                },
                            },
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                        hidden: true
                    },
                    {
                        label: this.$t('installment'),
                        field: 'installment',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('price'),
                        field: 'price',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return `${row.price} ${row.currency}`;
                        },
                    },
                    {
                        label: this.$t('currency'),
                        field: 'currency',
                        sortable: false,
                        hidden: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    computed: {
        formModalTitle() {
            if (this.formType == "add") {
                return `${this.$t('room')} ${this.$t('add')}`
            }
            return ""
        }
    },
    methods: {
        openForm(str, row = null) {
            if (str === "show" || str === "delete") {
                this.formDisabled = true
            }
            if (row) {
                // this.formId = row.id
                this.formData = { ...row }

            }
            this.formType = str
            this.$refs.formModal.$refs.commonModal.show()
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                    filter: {
                        unihall_quota_id: this.formId,
                    }

                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return UnihallQuotaPriceService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        // Form
        formClear(close = false) {
            if (close) {
                this.$refs.formModal.$refs.commonModal.hide()
            }
            // this.formId = null
            this.formType = ""
            this.formData = {}
            this.formDisabled = false
            this.getRows();
        },
    },
    mounted() {
        this.formId = this.$route.params.id;
        this.getRows()
    }
}
</script>
